html, body, #root {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0
}

img {
  border-style: none
}

input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}

input {
  overflow: visible
}

[type=submit] {
  -webkit-appearance: button
}

[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0
}

[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

[type=checkbox] {
  box-sizing: border-box;
  padding: 0
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

*, *:after, *:before {
  box-sizing: inherit
}

html {
  box-sizing: border-box;
  /* font-size: 62.5% */
  font-size: 100%
}

body {
  color: #606c76;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.6em;
  font-weight: 300;
  letter-spacing: .01em;
  line-height: 1.6
}

input[type='submit'] {
  background-color: #2c3e50;
  border: 0.1rem solid #2c3e50;
  border-radius: .4rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: .1rem;
  line-height: 3.8rem;
  padding: 0 3.0rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap
}

input[type='submit']:focus, input[type='submit']:hover {
  background-color: #606c76;
  border-color: #606c76;
  color: #fff;
  outline: 0
}

legend {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: .5rem
}

input[type='checkbox'] {
  display: inline
}

input {
  margin-bottom: 1.5rem
}

form, p, table {
  /* margin-bottom: 2.5rem */
  margin-bottom: auto;
}

table {
  border-spacing: 0;
  width: 100%
}

td, th {
  /* border-bottom: 0.1rem solid #e1e1e1; */
  /* padding: 1.2rem 1.5rem; */
  text-align: left
}

td:first-child, th:first-child {
  padding-left: 0
}

td:last-child, th:last-child {
  padding-right: 0
}

p {
  margin-top: 0
}

img {
  max-width: 100%
}